.Thanks {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0px 5%;
    .ThanksBox {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 40px 0px;
        // background-color: #f3f3fe;
        border-top: 4px solid #0157ff;
        border-bottom: 1px solid #4b657726;
        border-radius: 3px;
        margin-top: 20px;
        img {
            width: 60px;
            border-radius: 50%;
        }
        h1 {
            color: #0157ff;
            margin-top: 30px;
            font-size: 35px;
            text-align: center;
        }
        h3 {
            text-align: center;
            color: #082538;
            font-size: 16px;
            font-weight: 400;
            margin-top: 15px;
        }
    }
    .HowItWorkMainExplain {
        width: 100%;
        margin-top: 50px;
        padding-bottom: 60px;
        .HowItWorkMainExplainHeader {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;

            h1 {
                text-align: center;
                color: #082538;
                font-size: 32px;
                font-weight: 600;
            }
            h2 {
                text-align: center;
                color: #082538;
                font-size: 16px;
                font-weight: 400;
                margin-top: 15px;
            }
        }
        .HowItWorkMainExplainBody {
            width: 100%;
            position: relative;
            margin-top: 40px;

            .HowItWorkMainExplainBodyBoxLine {
                position: absolute;
                left: 18px;
                top: 36px;
                width: 2px;
                height: calc(100% - 36px);
                background-color: #0157ff;
                background: rgb(1, 87, 255);
                background: linear-gradient(263deg, rgba(1, 87, 255, 1) 34%, rgba(1, 217, 255, 1) 100%);
                z-index: 2;
            }
            .HowItWorkMainExplainBodyBox {
                background-color: white;
                // background-color: #f0f0f0;
                background-color: #f3f3fe;;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
                padding: 20px 40px;
                border-radius: 10px;
                position: relative;
                min-height: 170px;
                .HowItWorkMainExplainBodyBoxLineDot {
                    position: absolute;
                    left: 11px;
                    top: 36px;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background-color: #0157ff;
                    z-index: 3;
                }
                .HowItWorkMainExplainBodyBoxLeft {
                    width: 30%;
                    h2 {
                        color: #082538;
                        font-size: 14px;
                        font-weight: 400;
                    }
                    h3 {
                        color: #082538;
                        font-size: 28px;
                        font-weight: 600;
                        margin-top: 15px;
                    }
                }
                .HowItWorkMainExplainBodyBoxRight {
                    width: 70%;
                    h4 {
                        color: #082538;
                        font-size: 16px;
                        font-weight: 300;
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    .ThanksBottom {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        h6 {
            text-align: center;
            color: #000000db;
            font-size: 18px;
            font-weight: 300;
            text-align: center;
            border-bottom: 1px solid #f3f3fe;
            width: 100%;
            padding: 30px 0px;
            a {
                color: #0157ff;
            }
        }
        span {
            padding: 40px 0px 100px 0px;
            a {
                width: auto;
                margin-top: 30px;
                background-color: #0157ff;
                padding: 15px 30px;
                border-radius: 10px;
                color: white;
                font-size: 18px;
                font-weight: 300;
                margin-bottom: 80px;
                border-radius: 50px;
            }
        }
    }
}
@media (max-width: 1000px) {
    .Thanks {
        padding: 0px 5%;
        .ThanksBox {
            h1 {
                font-size: 25px;
            }
        }
        .HowItWorkMainExplain {
            padding: 0px 0%;
            padding-bottom: 60px;
            .HowItWorkMainExplainBody {
                .HowItWorkMainExplainBodyBox {
                    flex-direction: column;
                    padding: 35px 40px;
                    .HowItWorkMainExplainBodyBoxLeft {
                        width: 100%;
                    }
                    .HowItWorkMainExplainBodyBoxRight {
                        width: 100%;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1400px) {
    .Thanks {
        padding: 0px 13%;
    }
}

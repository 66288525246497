.ServicesAll {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    // padding: 50px 5% 0px 5%;
    .MainBanner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-width: 100vw;
        background-color: #0157ff;
        padding: 60px 5% 60px 5%;
        overflow: hidden;
        .MainBannerLeft {
            width: 50%;
            padding-right: 20px;
            h1 {
                font-size: 40px;
                font-weight: 600;
                color: white;
            }
            h2 {
                font-size: 17px;
                font-weight: 200;
                color: white;
                margin-top: 20px;
                margin-bottom: 20px;
            }
            h3 {
                display: flex;
                align-items: center;
                justify-content: start;
                font-size: 17px;
                font-weight: 400;
                color: white;
                margin-top: 0px;

            }
            span {
                display: flex;
                flex-direction: column;
                margin-top: 35px;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 19px;
                    font-weight: 500;
                    cursor: pointer;
                    background-color: white;
                    color: rgb(0, 0, 0);
                    cursor: pointer;
                    padding: 20px 20px;
                    padding-left: 20px;
                    margin-right: 20px;
                    margin-top: 20px;

                    img {
                        // background-color: white;
                        width: 20px;
                        margin-left: 20px;
                        filter: invert(0);
                    }
                }

                a:last-child {
                    background-color: #c3c3c300;
                    color: #ffffff;
                    border: 2px solid white;
                    img {
                        filter: invert(1);
                    }
                }
                a:hover,
                a:last-child:hover {
                    background-color: #e4e4e4;
                    color: black;
                    img {
                        filter: invert(0);
                    }
                }
            }
            .GoogleButt {
                display: flex;
                flex-direction: column;
                img {
                    width: 300px;
                    height: auto;
                }
                h6 {
                    font-size: 20px;
                    font-weight: 200;
                    color: white;
                }
            }
        }
        .MainBannerRight {
            display: flex;
            align-items: flex-end;
            justify-content: end;
            width: 50%;
            height: 100%;
            position: relative;
            img {
                position: absolute;
                bottom: -180px;
                margin-top: 100px;
                width: 100%;
                max-width: 600px;
                object-fit: cover;
                z-index: 2;
                border-radius: 40px;
            }
            .MainBannerRightBubble1 {
                // display: none;
                position: absolute;
                right: 290px;
                bottom: -200px;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background-color: #ffffff;
                opacity: 0.8;
                z-index: 3;
                box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
            }
            .MainBannerRightBubble2 {
                // display: none;
                position: absolute;
                right: 50px;
                bottom: -150px;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                opacity: 0.5;
                background-color: #ffffff;
                z-index: 3;
                box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
            }
        }
    }
    .ServicesAllTitle {
        padding: 50px 5% 50px 5%;
        background-color: #e9e9e99c;

        h1 {
            font-size: 30px;
            font-weight: 700;
            color: #023eb5;
        }

    }
    .ServicesAllLsit {
        padding: 0px 5% 50px 5%;
        background-color: #e9e9e99c;
        .ServicesAllBox {
            border-top: 3px solid #dcdcdc80;
            background-color: white;
            padding: 20px 10px;
            margin-top: 20px;
            border: 2px solid #0157ff;
            border-radius: 8px;
            padding: 20px;
            h2 {
                width: 100%;
                font-size: 35px;
                font-weight: 600;
                color: #023eb5;
                text-align: start;
            }
            h6 {
                width: 100%;
                font-size: 16px;
                font-weight: 300;
                color: #000000;
                text-align: start;
                margin-top: 20px;
            }
            h4 {
                width: 100%;
                font-size: 18px;
                font-weight: 400;
                color: #000000;
                text-align: start;
                margin-top: 20px;
            }
            h5 {
                width: 100%;
                font-size: 18px;
                font-weight: 600;
                text-align: start;
                margin-top: 20px;
                color: #0157ff;
                text-decoration: underline;
            }
        }
    }
}

@media (max-width: 850px) {
    .ServicesAll {
        .MainBanner {
            flex-direction: column;
            padding: 20px 5% 20px 5%;
            height: auto;
            min-height: auto;
            .MainBannerLeft {
                width: 100%;
                height: auto;
                margin-top: 30px;
                padding-right: 0px;
                h1 {
                    font-size: 35px;
                }
                h2 {
                    font-size: 20px;
                    margin-bottom: 20px;
                }
                h3 {
                    margin-top: 7px;
                }
                .GoogleButt {
                    img {
                        width: 200px;
                    }
                }
            }
            .MainBannerRight {
                width: 100vw;
                height: auto;
                background-color: rgb(255, 255, 255);
                justify-content: center;
                margin-top: 170px;
                padding-bottom: 100px;

                img {
                    // position: absolute;
                    // bottom: -250px;
                    // margin-top: 140px;
                    width: 80%;
                    max-height: 400px;
                    object-fit: cover;
                    border-radius: 5px;
                    z-index: 2;
                    padding-bottom: 100px;
                }
                .MainBannerRightBubble1 {
                    right: 190px;
                    bottom: -220px;
                    width: 120px;
                    height: 120px;
                }
                .MainBannerRightBubble2 {
                    right: 50px;
                    bottom: -130px;
                    width: 90px;
                    height: 90px;
                }
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .ServicesAll {

        .MainBanner,.ServicesAllTitle,.ServicesAllLsit {
            padding-right: 13%;
            padding-left: 13%;
        }
    }
}

.Careers {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    .MainBanner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-width: 100vw;
        background-color: #0157ff;
        padding: 60px 5% 60px 5%;
        overflow: hidden;
        .MainBannerLeft {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            padding-right: 20px;
            h1 {
                font-size: 40px;
                font-weight: 600;
                color: white;
                text-align: center;
            }
            h2 {
                font-size: 22px;
                font-weight: 200;
                text-align: center;
                color: white;
                margin-top: 20px;
            }
            span {
                display: flex;
                margin-top: 10px;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 19px;
                    font-weight: 500;
                    cursor: pointer;
                    background-color: white;
                    color: rgb(0, 0, 0);
                    cursor: pointer;
                    padding: 20px 20px;
                    padding-left: 20px;
                    margin-right: 20px;
                    margin-top: 20px;

                    img {
                        // background-color: white;
                        width: 20px;
                        margin-left: 20px;
                        filter: invert(0);
                    }
                }
                a:last-child {
                    background-color: #c3c3c300;
                    color: #ffffff;
                    border: 2px solid white;
                    img {
                        filter: invert(1);
                    }
                }
                a:hover,
                a:last-child:hover {
                    background-color: #e4e4e4;
                    color: black;
                    img {
                        filter: invert(0);
                    }
                }
            }
        }
    }
    .CareersBanner {
        padding: 0px 5% 0px 5%;

        h1 {
            width: 100%;
            font-size: 35px;
            font-weight: 600;
            color: #023eb5;
            text-align: start;
            margin-top: 40px;
        }
        h2 {
            width: 100%;
            font-size: 18px;
            font-weight: 300;
            color: #000000;
            text-align: start;
            margin-top: 10px;
        }
        span {
            display: flex;
            align-items: center;
            margin-top: 60px;
            .CareersBannerLeft {
                margin-right: 25px;
                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
            .CareersBannerRight {
                h4 {
                    width: 100%;
                    font-size: 35px;
                    font-weight: 600;
                    color: #023eb5;
                    text-align: start;
                }
                h5 {
                    font-size: 18px;
                    font-weight: 600;
                    color: #000000;
                    text-align: start;
                    margin-top: 5px;
                }
                h6 {
                    font-size: 17px;
                    font-weight: 300;
                    color: #000000;
                    text-align: start;
                    margin-top: 20px;
                }
            }
        }
    }
    .CareersBanner2 {
        padding: 50px 0px;
        padding: 50px 5% 50px 5%;
        margin-top: 70px;
        height: auto;
        background-color: #e9e9e99c;
        h1 {
            width: 100%;
            font-size: 35px;
            font-weight: 600;
            color: #023eb5;
            text-align: start;
        }
        .CareersBanner2List {
            display: flex;
            flex-direction: column;
            margin-top: 0px;
            .CareersBanner2ListTop {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 30px;
                margin-top: 30px;
                .CareersBanner2ListBox {
                    position: relative;
                    width: 33.3%;
                    height: 290px;
                    img {
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                    }
                    h2 {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 20px 20px;
                        display: flex;
                        align-items: center;
                        justify-content: end;
                        width: 100%;
                        font-size: 20px;
                        font-weight: 500;
                        color: white;
                        background-color: #0157ff;
                        text-align: start;
                    }
                }
            }
        }
        h2 {
            width: 100%;
            font-size: 18px;
            font-weight: 400;
            margin-top: 30px;
            color: #000000;
            text-align: start;
        }
    }
}

@media (max-width: 850px) {
    .Careers {
        .MainBanner {
            flex-direction: column;
            padding: 20px 5% 50px 5%;
            height: auto;
            min-height: auto;
            .MainBannerLeft {
                width: 100%;
                height: auto;
                margin-top: 30px;
                padding-right: 0px;
                h1 {
                    font-size: 35px;
                    text-align: left;
                    width: 100%;
                }
                h2 {
                    font-size: 20px;
                    text-align: left;
                    margin-bottom: 20px;
                    width: 100%;
                }
                span {
                    flex-direction: column;
                    margin-top: 0px;
                    width: 100%;
                    a {
                        margin-right: 0px;
                        width: 100%;
                    }
                }
            }
        }
        .CareersBanner {
            h2 {
                font-size: 17px;
            }
        }
        .CareersBanner2 {
            h1 {
                font-size: 25px;
            }
            .CareersBanner2List{
                .CareersBanner2ListTop{
                    flex-direction: column;
                    .CareersBanner2ListBox{
                        width: 100%;
                    }
                }
            }
            h2 {
                font-size: 15px;
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .Careers {
        .MainBanner,
        .CareersBanner,
        .CareersBanner2 {
            padding-right: 13%;
            padding-left: 13%;
        }
    }
}

.BlogEditor {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    padding-top: 0px;
    padding: 40px;
    min-height: 100vh;
    max-height: 100vh;
    .jodit-react-container {
        min-height: 100vh;
        max-height: 100vh;
    }
    .jodit-container {
        min-height: 100vh;
        max-height: 100vh;
    }
    .jodit-workplace{
        min-height: 100vh;
        max-height: 100vh;

    }
    .jodit-wysiwyg {
        min-height: 100vh;
        max-height: 100vh;
    }
}
@media (max-width: 690px) {
    .BlogEditor {
        padding: 0px 0% 30px 0%;
    }
}
@media screen and (min-width: 1400px) {
    .BlogEditor {
        // d
    }
}

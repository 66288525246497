.NavigatorPath {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  margin-top: 20px;
  padding: 20px 5%;
  .NavigatorPathBox {
    display: flex;
    align-items: center;
    margin: 5px 5px 0px 0px;
    a {
      font-size: 14px;
      font-weight: 400;
      // white-space: nowrap;
      color: black;
      text-decoration: underline;
      cursor: pointer;
    }
    img {
      width: 20px;
      height: 14px;
      transform: rotate(0deg);
    }
  }
}

@media (max-width: 590px) {
  .NavigatorPath {
    padding: 20px 5%;
  }
}
@media screen and (min-width: 1400px) {
  .NavigatorPath {
    padding-right: 13%;
    padding-left: 13%;
  }
}

.ServicesPage {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    // MainBanner
    .MainBanner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-width: 100vw;
        background-color: #0157ff;
        padding: 100px 5% 100px 5%;
        overflow: hidden;
        .MainBannerLeft {
            width: 50%;
            padding-right: 20px;
            h1 {
                font-size: 45px;
                font-weight: 600;
                color: white;
            }
            h2 {
                font-size: 20px;
                font-weight: 200;
                color: white;
                margin-top: 20px;
                margin-bottom: 60px;
            }
            h3 {
                display: flex;
                align-items: center;
                justify-content: start;
                font-size: 22px;
                font-weight: 400;
                color: white;
                margin-top: 20px;
                img {
                    width: 30px;
                    margin-right: 10px;
                }
            }
            span {
                display: flex;
                flex-direction: column;
                margin-top: 35px;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 19px;
                    font-weight: 500;
                    cursor: pointer;
                    background-color: white;
                    color: rgb(0, 0, 0);
                    cursor: pointer;
                    padding: 20px 20px;
                    padding-left: 20px;
                    margin-right: 20px;
                    margin-top: 20px;

                    img {
                        // background-color: white;
                        width: 20px;
                        margin-left: 20px;
                        filter: invert(0);
                    }
                }

                a:last-child {
                    background-color: #c3c3c300;
                    color: #ffffff;
                    border: 2px solid white;
                    img {
                        filter: invert(1);
                    }
                }
                a:hover,
                a:last-child:hover {
                    background-color: #e4e4e4;
                    color: black;
                    img {
                        filter: invert(0);
                    }
                }
            }
            .GoogleButt {
                display: flex;
                flex-direction: column;
                img {
                    width: 300px;
                    height: auto;
                }
                h6 {
                    font-size: 20px;
                    font-weight: 200;
                    color: white;
                    a{
                        color: white;
                        text-decoration: underline;
                    }
                }
            }
        }
        .MainBannerRight {
            display: flex;
            align-items: flex-end;
            justify-content: end;
            width: 50%;
            height: 100%;
            position: relative;
            img {
                position: absolute;
                bottom: -180px;
                margin-top: 100px;
                width: 100%;
                max-width: 600px;
                object-fit: cover;
                z-index: 2;
                border-radius: 40px;
            }
            .MainBannerRightBubble1 {
                // display: none;
                position: absolute;
                right: 290px;
                bottom: -200px;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background-color: #ffffff;
                opacity: 0.8;
                z-index: 3;
                box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
            }
            .MainBannerRightBubble2 {
                // display: none;
                position: absolute;
                right: 50px;
                bottom: -150px;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                opacity: 0.5;
                background-color: #ffffff;
                z-index: 3;
                box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
            }
        }
    }
    .MainBannerMainBanner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-direction: row-reverse;
        min-width: 100vw;
        min-height: 24vh;
        max-height: 24vh;
        background-color: #e6e6e6;
        padding: 20px 5% 20px 5%;
        overflow: hidden;
        .MainBannerMainBannerLeft {
            display: flex;
            ruby-align: center;
            flex-direction: column;
            width: 50%;
            h1 {
                font-size: 30px;
                font-weight: 700;
                color: #000000;
            }
            h2 {
                font-size: 15px;
                font-weight: 300;
                color: #000000;
                margin-top: 15px;
            }
            h3 {
                font-size: 22px;
                font-weight: 400;
                color: #000000;
                margin-top: 15px;
            }
        }
        .MainBannerMainBannerRight {
            display: flex;
            align-items: flex-start;
            justify-content: start;
            width: 50%;
            height: 100%;
            position: relative;
            img {
                max-height: 18vh;
                object-fit: cover;
                width: 80%;
                border-radius: 5px;
            }
            h3 {
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: #0157ff;
                color: rgb(255, 255, 255);
                display: flex;
                align-items: center;
                justify-content: center;
                width: 80%;
                padding: 10px 10px;
                font-size: 20px;
                border-radius: 0px 0px 5px 5px;
            }
        }
    }
    .WebSiteBanner {
        display: flex;
        width: 100%;
        height: auto;
        padding: 50px 5% 100px 5%;
        .WebSiteBannerLeft {
            width: 70%;
            padding-right: 5%;
            p {
                margin-top: 20px;
                align-self: flex-start;
                text-align: start;
                font-size: 17px;
                font-weight: 300;
                color: #242424;
                margin-top: 10px;
            }
            h4 {
                margin-top: 20px;
            }
            hr {
                margin: 30px 0px;
            }
            ol,
            ul {
                li {
                    margin-top: 20px;
                    font-size: 20px;
                    text-align: left;
                    font-size: 17px;
                    font-weight: 400;
                    color: #242424;
                    margin-top: 25px;
                    margin-bottom: 20px;
                }
            }
        }
        .WebSiteBannerRight {
            width: 30%;
            .LocationAllBox {
                display: flex;
                flex-direction: column;
                border-top: 3px solid #dcdcdc80;
                background-color: white;
                padding: 15px 15px;
                margin-bottom: 25px;
                border: 1px solid #cacaca;
                border-radius: 5px;
                h2 {
                    width: 100%;
                    font-size: 22px;
                    font-weight: 600;
                    color: #023eb5;
                    text-align: start;
                }
                h4 {
                    width: 100%;
                    font-size: 16px;
                    font-weight: 400;
                    color: #000000;
                    text-align: start;
                    margin-top: 10px;
                }
                span {
                    display: flex;
                    justify-content: space-between;
                    // flex-direction: column;
                    margin-top: 10px;
                    width: 100%;
                    gap: 15px;
                    a {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 10px 10px;
                        color: white;
                        background-color: #0157ff;
                        border-radius: 5px;
                        border: 2px solid #0157ff;
                        margin-right: 0px;
                        font-size: 13px;
                        margin-top: 10px;
                        img {
                            width: 20px;
                            margin-right: 10px;
                            filter: invert(1);
                            // filter: invert(13%) sepia(62%) saturate(4816%) hue-rotate(219deg) brightness(107%) contrast(102%);
                        }
                    }
                    a:hover {
                        color: #0157ff;
                        background-color: white;
                        border: 2px solid #0157ff;
                        img {
                            filter: invert(13%) sepia(62%) saturate(4816%) hue-rotate(219deg) brightness(107%)
                                contrast(102%);
                        }
                    }
                }
                h5 {
                    border-radius: 5px;
                    width: 100%;
                    font-size: 12px;
                    font-weight: 600;
                    text-align: start;
                    margin-top: 10px;
                    color: #0157ff;
                    text-decoration: underline;
                    a {
                        color: #0157ff;
                    }
                }
            }
            h1 {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                font-size: 22px;
                font-weight: 600;
                color: #000000;
                text-align: start;
                margin-bottom: 20px;
                border-bottom: 2px solid #cacaca;
                padding-bottom: 10px;
                a {
                    font-size: 18px;
                    text-decoration: underline;
                    color: #0157ff;
                }
            }
            .WebSiteBannerRightLsit {
                .LocationAllBox1 {
                    background-color: #dcdcdc80;
                    border: 2px solid #0157ff;
                }
            }
            h6 {
                width: 100%;
                font-size: 22px;
                font-weight: 600;
                color: #0157ff;
                text-align: start;
                border-bottom: 1px solid #cacaca;
                text-decoration: underline;
                padding: 20px 0px;
                a {
                    color: #0157ff;
                }
            }
        }
    }
    .MainBanner2Top2 {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        background-color: #e9e9e99c;
        position: relative;
        z-index: 2;

        .MainBanner2TopBox {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 30px 50px 20px 50px;
            background-color: white;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            border-radius: 8px;
            width: 90%;
            margin-top: -40px;
            h2 {
                text-align: start;
                font-size: 23px;
                font-weight: 600;
                color: #0157ff;
            }
            .MainBanner2TopBoxMenu {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
                .MainBanner2TopBoxBox {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    border-right: 1px solid #e9e9e988;
                    padding: 15px 10px;
                    width: 25%;
                    h3 {
                        text-align: center;
                        font-size: 18px;
                        font-weight: 500;
                        color: rgb(61, 61, 61);
                        margin-top: 10px;
                    }
                    img {
                        width: 50px;
                    }
                }
                .MainBanner2TopBoxBox:hover {
                    background-color: #0156ff11;
                    border-radius: 15px;
                }
                .MainBanner2TopBoxBox:last-child {
                    border-right: none;
                }
            }
        }
    }
    .MainBannerReviews {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 50px 5% 0px 5%;
        width: 100%;
        background-color: #e9e9e99c;
        .MainBannerReviewsHeader {
            h1 {
                text-align: center;
                font-size: 23px;
                font-weight: 600;
                color: #0157ff;
            }
        }
        .MainBannerReviewsMenu {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 30px;
            gap: 30px;
            overflow: scroll;
            .MainBannerReviewsMenuBox {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #ffffff;
                min-width: 200px;
                max-width: 200px;
                min-height: 130px;
                max-height: 130px;
                padding: 30px 30px;
                border-radius: 8px;
                img {
                    width: 100%;
                    height: 100%;
                    filter: invert(58%) sepia(10%) saturate(9%) hue-rotate(314deg) brightness(95%) contrast(106%);
                }
            }
            .MainBannerReviewsMenuBox:hover {
                img {
                    filter: none;
                }
            }
        }
    }
    // MainBanner2
    .MainBanner2 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 50px 5%;
        background-color: #e9e9e99c;
        .MainBanner2TopTop {
            display: flex;
            height: 300px;
            width: 100%;
            // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

            .MainBanner2TopTopLeft {
                width: 50%;
                height: 100%;
                padding: 8px;
                background-color: white;
                border-radius: 8px 0px 0px 8px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                }
            }
            .MainBanner2TopTopRight {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 50%;
                height: 100%;
                padding: 20px;
                background-color: white;
                border-radius: 0px 8px 8px 0px;
                .MainBanner2TopBox {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    background-color: white;
                    padding-left: 30px;
                    height: 33.3%;
                    border-bottom: 1px solid #e9e9e99c;
                    padding-bottom: 5px;
                    padding-top: 5px;

                    h2 {
                        text-align: start;
                        font-size: 20px;
                        font-weight: 600;
                        color: #0157ff;
                    }
                    h3 {
                        text-align: start;
                        font-size: 15px;
                        font-weight: 300;
                        color: rgb(61, 61, 61);
                        margin-top: 5px;
                    }
                }
                .MainBanner2TopBox:last-child {
                    border-bottom: none;
                }
            }
        }
        // Title
        h1 {
            margin-top: 60px;
            text-align: center;
            font-size: 30px;
            font-weight: 600;
            color: #0157ff;
            padding: 0px 5%;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 35px;
            padding: 15px 45px;
            border-radius: 50px;
            background-color: #0157ff;
            border: unset;
            outline: unset;
            font-size: 30px;
            font-weight: 700;
            color: #ffffff;
            cursor: pointer;
            margin-top: 40px;
            img {
                width: 20px;
            }
        }
        .MainBanner2Bottom {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 30px;
            margin-top: 30px;
            width: 100%;
            height: auto;
            .MainBanner2BottomBox {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: column;
                padding: 30px 60px;
                background-color: white;
                box-shadow: rgba(100, 100, 111, 0.088) 0px 7px 29px 0px;
                border-radius: 10px;
                width: 100%;
                height: auto;
                min-height: 550px;
                h2 {
                    align-self: flex-start;
                    text-align: start;
                    font-size: 23px;
                    font-weight: 600;
                    color: #0157ff;
                    margin-top: 10px;
                }
                h3 {
                    text-align: left;
                    font-size: 17px;
                    font-weight: 400;
                    color: #242424;
                    margin-top: 25px;
                    margin-bottom: 20px;
                }
                a {
                    align-self: center;
                    margin-top: 35px;
                    padding: 10px 45px;
                    border-radius: 50px;
                    background-color: white;
                    border: unset;
                    outline: unset;
                    font-size: 20px;
                    font-weight: 500;
                    color: #0157ff;
                    border: 1px solid #0157ff;
                    cursor: pointer;
                    text-decoration: unset;
                }
            }
        }
    }
    // MainBanner3
    .MainBanner3 {
        display: none;
        padding: 50px 5%;
        background-color: white;
        h1 {
            text-align: center;
            font-size: 33px;
            font-weight: 600;
            color: #15026a;
        }
        .MainBanner3List {
            display: flex;
            align-items: start;
            justify-content: center;
            gap: 35px;
            margin-top: 30px;
            .MainBanner3Box {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                margin-top: 50px;
                width: 33%;
                .MainBanner3BoxIMG {
                    width: 200px;
                    height: 70px;
                    background-color: #acfff1;
                    position: relative;
                    border-radius: 50% 50% 0px 0px;
                    border-bottom: 2px solid #82c1b6;
                    img {
                        bottom: 20px;
                        left: 60px;
                        position: absolute;
                        width: 80px;
                        background-color: white;
                        border: 5px solid white;
                        padding: 10px;
                        border-radius: 20%;
                    }
                }
                h3 {
                    text-align: center;
                    font-size: 30px;
                    font-weight: 600;
                    margin-top: 20px;
                    color: #15026a;
                }
                h4 {
                    text-align: center;
                    font-size: 15px;
                    font-weight: 300;
                    color: rgb(61, 61, 61);
                    margin-top: 10px;
                }
            }
            .MainBanner3Box:nth-child(1) {
                .MainBanner3BoxIMG {
                    background-color: #ffbeac;
                    border-bottom: 2px solid #ae867c;
                }
            }
            .MainBanner3Box:nth-child(2) {
                .MainBanner3BoxIMG {
                    background-color: #fface1;
                    border-bottom: 2px solid #aa6e94;
                }
            }
        }
    }
    // MainBanner4
    .MainBanner4 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: 50vh;
        min-width: 100vw;
        background-color: #ffffff;
        padding: 120px 5% 170px 5%;
        .MainBanner4Left {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            position: relative;
            img {
                max-width: 250px;
                min-height: 400px;
                object-fit: cover;
                border-radius: 0% 10% 100% 0px;
                z-index: 2;
            }
            .MainBanner4LeftBubble1 {
                position: absolute;
                left: -20px;
                bottom: 100px;
                width: 200px;
                height: 200px;
                border-radius: 50%;
                background-color: #0157ff;
                z-index: 1;
            }
            .MainBanner4LeftBubble2 {
                position: absolute;
                right: 0px;
                bottom: -100px;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background-color: #0157ff;
                z-index: 3;
            }
        }
        .MainBanner4Right {
            width: 70%;
            h1 {
                font-size: 45px;
                font-weight: 600;
                color: #0157ff;
            }
            h2 {
                font-size: 17px;
                font-weight: 300;
                color: #0f212e;
                margin-top: 15px;
            }
            .MainBanner4RightBenefits {
                margin-top: 30px;
                display: flex;
                flex-wrap: wrap;
                background-color: white;
                padding: 20px 20px;
                border-radius: 5px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                gap: 20px;
                border-radius: 8px;
                a {
                    display: flex;
                    align-items: center;
                    background-color: #0156ff1f;
                    border-left: 1px solid #0157ff;
                    padding: 5px 7px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 300;
                    color: rgb(61, 61, 61);
                }
                .MainBanner4RightBenefitsBox {
                    display: flex;
                    align-items: center;
                    background-color: #0156ff1f;
                    border-left: 1px solid #0157ff;
                    padding: 5px 7px;
                    img {
                        display: none;
                        width: 25px;
                        height: 25px;
                    }
                    h3 {
                        text-align: center;
                        font-size: 15px;
                        font-weight: 300;
                        color: rgb(61, 61, 61);
                    }
                }
            }
        }
    }
    // MainBanner5
    .MainBanner5 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: auto;
        min-width: 100vw;
        background-color: #e9e9e99c;
        padding: 80px 5% 120px 5%;
        .MainBanner5Left {
            display: flex;
            flex-direction: column;
            width: 70%;
            h1 {
                font-size: 33px;
                font-weight: 600;
                color: #0157ff;
            }
            .MainBanner5LeftBox {
                display: flex;
                align-items: center;
                margin-top: 30px;
                h2 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 33px;
                    font-weight: 800;
                    color: #0157ff;
                    background-color: #0156ff1c;
                    border-radius: 50%;
                    min-width: 50px;
                    min-height: 50px;
                    margin-right: 20px;
                }
                .MainBanner5LeftBoxRigth {
                    h3 {
                        font-size: 20px;
                        font-weight: 500;
                        color: rgb(43, 43, 43);
                    }
                    h4 {
                        font-size: 15px;
                        font-weight: 300;
                        color: rgb(61, 61, 61);
                        margin-top: 10px;
                    }
                }
            }
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 35px;
                padding: 15px 45px;
                border-radius: 50px;
                background-color: #0157ff;
                border: unset;
                outline: unset;
                font-size: 30px;
                font-weight: 700;
                color: #ffffff;
                cursor: pointer;
                margin-top: 40px;
                img {
                    width: 20px;
                }
            }
        }
        .MainBanner5Right {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            position: relative;
            img {
                max-width: 250px;
                min-height: 400px;
                object-fit: cover;
                border-radius: 0% 100% 10% 0px;
                z-index: 2;
            }
            .MainBanner5RightBubble1 {
                position: absolute;
                left: -20px;
                bottom: 100px;
                width: 200px;
                height: 200px;
                border-radius: 50%;
                background-color: #0157ff;
                z-index: 1;
            }
            .MainBanner5RightBubble2 {
                position: absolute;
                right: 0px;
                bottom: -100px;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background-color: #0157ff;
                z-index: 3;
            }
        }
    }
    // MainBanner6
    .MainBanner6 {
        padding: 60px 5%;
        background-color: #e9e9e99c;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 2px solid #0157ff;
        flex-direction: column;
        h1 {
            text-align: center;
            font-size: 30px;
            font-weight: 600;
            color: #0157ff;
        }
        h2 {
            text-align: center;
            font-size: 17px;
            font-weight: 500;
            margin-top: 10px;
            margin-top: 20px;
            color: #00000090;
        }
        h3 {
            text-align: center;
            font-size: 25px;
            font-weight: 600;
            color: #0157ff;
            margin-top: 20px;
        }
        .MainBanner6List {
            display: flex;
            gap: 20px;
            margin-top: 40px;
            .MainBanner6TopBox {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                padding: 30px 30px;
                background-color: white;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border-radius: 5px;
                h2 {
                    text-align: start;
                    font-size: 23px;
                    font-weight: 600;
                    color: #0157ff;
                }
                h3 {
                    text-align: start;
                    font-size: 15px;
                    font-weight: 300;
                    color: rgb(61, 61, 61);
                    margin-top: 5px;
                }
            }
        }
    }
    .MainBanner7 {
        padding: 60px 5%;
        background-color: #e9e9e99c;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 2px solid #0157ff;
        flex-direction: column;
    }

    // Blog and Faq
    .BlogsListFaq {
        display: flex;
        flex-direction: column;
        padding: 30px 5% 60px 5%;
        width: 100%;
        .BlogsListFaqHead {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #4b657726;
            padding: 20px 0px;
            h1 {
                font-size: 25px;
                font-weight: 500;
                color: #000000;
            }
            a {
                font-size: 17px;
                font-weight: 400;
                color: #0157ff;
            }
            a:hover {
                text-decoration: underline;
            }
        }
        .BlogsListFaqList {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            .FaqListBox {
                padding: 25px 20px;
                border-left: 2px solid #0157ff;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 09px 0px;
                border-radius: 5px;
                margin-top: 25px;
                cursor: pointer;
                a {
                    text-decoration: unset;
                    width: 100%;
                    height: 100%;
                    h1 {
                        font-size: 1.2rem;
                        font-weight: 600;
                        color: #151515;
                    }
                    h2 {
                        font-size: 16px;
                        font-weight: 300;
                        color: #6e6f81;
                        margin-top: 5px;
                    }
                    h3 {
                        font-size: 14px;
                        font-weight: 300;
                        color: #373841;
                        margin-top: 5px;
                        margin-top: 20px;
                        span {
                            background-color: #0156ff38;
                            padding: 3px 10px;
                            border-radius: 3px;
                            border-left: 2px solid #0157ff;
                            width: auto;
                        }
                    }
                }
            }
            .FaqListBox:hover {
                transform: scale(1.03);
                transition: 0.5s;
            }
        }
    }
    .BlogsListReview {
        display: flex;
        flex-direction: column;
        padding: 40px 5% 70px 5%;
        width: 100%;
        gap: 20px;
        background-color: #f3f4f8;
        .BlogsListReviewHead {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #4b657726;
            padding: 20px 0px;
            h1 {
                font-size: 25px;
                font-weight: 500;
                color: #000000;
            }
            a {
                font-size: 17px;
                font-weight: 400;
                color: #0157ff;
            }
            a:hover {
                text-decoration: underline;
            }
        }
        .BlogsListReviewList {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .BlogsListReviewBox {
                article {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                }
                display: flex;
                flex-direction: column;
                cursor: pointer;
                text-decoration: unset;
                width: 30%;
                background-color: white;
                margin-top: 10px;
                border-radius: 5px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 09px 0px;

                .BlogsListReviewBoxLeft {
                    width: 100%;
                    max-height: 180px;
                    img {
                        max-height: 180px;
                        width: 100%;
                        object-fit: cover;
                        transition: all 0.5s ease-in-out;
                        border-radius: 5px 5px 0px 0px;
                    }
                }
                .BlogsListReviewBoxRight {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 20px;
                    h1 {
                        font-size: 15px;
                        font-weight: 500;
                        color: #0f212e;
                        width: 100%;
                    }
                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 30px;
                        width: 100%;
                        h5 {
                            color: #000000;
                            font-size: 15px;
                            font-weight: 500;
                        }
                        h4 {
                            color: #4f6473;
                            font-size: 13px;
                            font-weight: 300;
                        }
                    }
                }
            }
            .BlogsListReviewBox:hover {
                position: relative;
                .BlogsListReviewBoxRight {
                    h2 {
                        color: #0f212e;
                    }
                    img {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
    // ----

    // MainBanner7
    .MainBanner7 {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-width: 100vw;
        background-color: #0157ff;

        padding: 100px 5% 100px 5%;
        .MainBanner7Left {
            width: 50%;
            padding-right: 20px;
            h1 {
                font-size: 37px;
                font-weight: 600;
                color: white;
            }
            h2 {
                font-size: 18px;
                font-weight: 300;
                color: white;
                margin-top: 15px;
            }
            button {
                margin-top: 35px;
                padding: 15px 45px;
                border-radius: 50px;
                background-color: white;
                border: unset;
                outline: unset;
                font-size: 30px;
                font-weight: 700;
                color: #0157ff;
                cursor: pointer;
            }
        }
        .MainBanner7Right {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            position: relative;
            img {
                max-width: 400px;
                max-height: 400px;
                border-radius: 60% 60%;
                z-index: 2;
            }
            .MainBanner7RightBubble1 {
                position: absolute;
                left: -20px;
                bottom: 100px;
                width: 100px;
                height: 200px;
                border-radius: 50%;
                background-color: #ffffff;
                z-index: 1;
            }
            .MainBanner7RightBubble2 {
                position: absolute;
                right: 0px;
                bottom: 0px;
                width: 150px;
                height: 40px;
                border-radius: 50%;
                background-color: #ffffff;
                z-index: 3;
            }
        }
    }
}
@media (max-width: 850px) {
    .ServicesPage {
        .MainBanner {
            flex-direction: column;
            padding: 20px 5% 20px 5%;
            height: auto;
            min-height: auto;
            .MainBannerLeft {
                width: 100%;
                height: auto;
                margin-top: 30px;
                padding-right: 0px;
                h1 {
                    font-size: 35px;
                }
                h2 {
                    font-size: 20px;
                    margin-bottom: 20px;
                }
                h3 {
                    margin-top: 7px;
                }
                .GoogleButt {
                    img {
                        width: 200px;
                    }
                }
            }
            .MainBannerRight {
                width: 100vw;
                height: auto;
                background-color: rgb(255, 255, 255);
                justify-content: center;
                margin-top: 170px;
                padding-bottom: 100px;

                img {
                    // position: absolute;
                    // bottom: -250px;
                    // margin-top: 140px;
                    width: 80%;
                    max-height: 400px;
                    object-fit: cover;
                    border-radius: 5px;
                    z-index: 2;
                    padding-bottom: 100px;
                }
                .MainBannerRightBubble1 {
                    right: 190px;
                    bottom: -220px;
                    width: 120px;
                    height: 120px;
                }
                .MainBannerRightBubble2 {
                    right: 50px;
                    bottom: -130px;
                    width: 90px;
                    height: 90px;
                }
            }
        }
        .MainBannerMainBanner {
            flex-direction: column;
            height: auto;
            min-height: none;
            max-height: none;
            .MainBannerMainBannerLeft {
                width: 100%;
                h3 {
                    font-size: 19px;
                }
            }
            .MainBannerMainBannerRight {
                width: 100%;
                margin-top: 30px;
                img {
                    max-height: 28vh;
                    width: 100%;
                }
                h3 {
                    width: 100%;
                    font-size: 17px;
                }
            }
        }
        .WebSiteBanner {
            padding: 50px 5% 100px 5%;
            flex-direction: column;
            .WebSiteBannerLeft {
                padding-right: 0%;
                width: 100%;
            }
            .WebSiteBannerRight {
                margin-top: 30px;
                width: 100%;
                .Header1BoxMenuBox {
                    .LocationAllBox {
                        span {
                            gap: 0px;
                            flex-direction: column;
                            a {
                                margin-top: 10px;
                            }
                        }
                    }
                }
                .WebSiteBannerRightLsit {
                    padding: 20px;
                    background-color: #e9e9e99c;
                    border-radius: 5px;
                    height: 730px;
                    overflow: hidden;
                }
            }
        }
        .MainBanner2Top2 {
            .MainBanner2TopBox {
                padding: 30px 10px 20px 10px;
                .MainBanner2TopBoxMenu {
                    .MainBanner2TopBoxBox {
                        width: 25%;
                        height: 150px;
                        justify-content: start;
                        padding: 15px 10px;

                        h3 {
                            text-align: center;
                            font-size: 14px;
                            font-weight: 500;
                            color: rgb(61, 61, 61);
                            margin-top: 10px;
                        }
                        img {
                            width: 50px;
                        }
                    }
                }
            }
        }
        .MainBannerReviews {
            padding: 40px 5% 0px 5%;

            .MainBannerReviewsHeader {
                h1 {
                    font-size: 18px;
                }
            }
            .MainBannerReviewsMenu {
                overflow: scroll;
                gap: 20px;
                .MainBannerReviewsMenuBox {
                    // margin-right: 20px;
                    min-width: 120px;
                    min-height: 100px;
                    padding: 20px 20px;

                    img {
                    }
                }
            }
        }
        .MainBanner2 {
            padding: 30px 0%;
            height: auto;

            .MainBanner2TopTop {
                padding-left: 5%;
                padding-right: 5%;
                height: 450px;

                .MainBanner2TopTopRight {
                    padding: 10px;
                    .MainBanner2TopBox {
                        padding-left: 10px;
                        justify-content: start;
                        padding: 15px 0px;
                        h2 {
                            text-align: left;
                            font-size: 17px;
                        }
                        h3 {
                            text-align: left;
                            font-size: 13px;
                        }
                    }
                }
            }
            h1 {
                margin-top: 50px;
                font-size: 26px;
            }
            a {
                font-size: 20px;
            }
            .MainBanner2Bottom {
                flex-direction: column;
                padding-left: 5%;
                padding-right: 5%;
                height: auto;
                gap: 40px;
                margin-top: 40px;

                .MainBanner2BottomBox {
                    padding: 10px 40px 60px 40px;
                    min-height: 500px;
                    h2 {
                        font-size: 21px;
                        margin-top: 0px;
                    }
                    h3 {
                        font-size: 15px;
                    }
                    h4 {
                        font-size: 15px;
                    }
                }
            }
        }
        .MainBanner3 {
            .MainBanner3List {
                flex-direction: column;
                align-items: center;
                .MainBanner3Box {
                    width: 100%;
                }
            }
        }
        .MainBanner4 {
            flex-direction: column-reverse;
            padding: 50px 5% 100px 5%;
            .MainBanner4Left {
                width: 100%;
                margin-top: 50px;
                margin-bottom: -180px;
                img {
                    max-width: 150px;
                    min-height: 300px;
                }
                .MainBanner4LeftBubble1 {
                    bottom: 50px;
                    left: 20px;
                }
                .MainBanner4LeftBubble2 {
                    bottom: -50px;
                }
            }
            .MainBanner4Right {
                width: 100%;
                h1 {
                    font-size: 35px;
                }
                .MainBanner4RightBenefits {
                    padding: 20px 20px;
                }
            }
        }
        .MainBanner5 {
            padding-top: 170px;
            padding-bottom: 70px;
            .MainBanner5Left {
                width: 100%;
            }
            .MainBanner5Right {
                display: none;
            }
        }
        .MainBanner6 {
            h1 {
                font-size: 30px;
            }
            .MainBanner6List {
                flex-direction: column;
                width: 100%;
                .MainBanner6TopBox {
                    width: 100%;
                }
            }
        }

        // Blog and Faq
        .BlogsListFaq {
            .BlogsListFaqHead {
                align-items: start;
                flex-direction: column;
                h1 {
                    margin-bottom: 10px;
                }
            }
        }
        .BlogsListReview {
            overflow: auto;
            gap: 0px;

            // BlogsListReviewHead
            .BlogsListReviewHead {
                padding-top: 0px;
            }
            .BlogsListReviewList {
                flex-direction: column;
                justify-content: start;
                .BlogsListReviewBox {
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }
        // ----

        .MainBanner7 {
            flex-direction: column;
            .MainBanner7Left {
                padding-right: 0px;
                width: 100%;
            }
            .MainBanner7Right {
                width: 100%;
                margin-top: 120px;
                img {
                    max-width: 90%;
                }
                .MainBanner7RightBubble1 {
                    bottom: 70px;
                    left: 10px;
                }
                .MainBanner7RightBubble2 {
                }
            }
        }
    }
}
@media screen and (min-width: 1400px) {
    .ServicesPage {
        .MainBanner {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBannerMainBanner {
            padding-right: 13%;
            padding-left: 13%;
        }
        .WebSiteBanner {
            padding: 50px 5% 100px 5%;
            padding-right: 13%;
            padding-left: 13%;
        }

        .MainBanner2Top2 {
            padding-right: 13%;
            padding-left: 13%;

            .MainBanner2TopBox {
                width: 100%;
            }
        }
        .MainBannerReviews {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner2 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner3 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner4 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner5 {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner6 {
            padding-right: 13%;
            padding-left: 13%;
        }

        // Blog and Faq
        .BlogsListFaq {
            padding-right: 13%;
            padding-left: 13%;
        }
        .BlogsListReview {
            padding-right: 13%;
            padding-left: 13%;
        }
        // ----

        .MainBanner7 {
            padding-right: 13%;
            padding-left: 13%;
        }
    }
}

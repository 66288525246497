.Header {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 0;
  height: auto;
  backdrop-filter: blur(40px);
  z-index: 5;
  border-bottom: 1px solid #e9e9e99c;

  .HeaderTopComputer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 80px;
    padding: 30px 5%;
    // box-shadow: 0px 10px 15px -3px #0000001a;
    border-bottom: 2px solid #e6e6e6;
    z-index: 1;
    position: relative;
    .Header2 {
      display: flex;
      align-items: flex-start;
      justify-content: start;
      cursor: pointer;
      flex-direction: column;
      img {
        display: none;
        width: 28px;
        border-radius: 3px;
        margin-right: 10px;
      }
      a {
        font-size: 25px;
        white-space: nowrap;
        font-weight: 700;
        color: black;
      }
      h2 {
        font-size: 15px;
        white-space: nowrap;
        font-weight: 300;
      }
    }
    .Header1 {
      display: flex;
      .Header1Box {
        padding: 20px 0px;
        margin-left: 30px;
        .Header1BoxHedaer {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          h1,
          a {
            white-space: nowrap;
            text-align: center;
            font-size: 18px;
            font-weight: 500;
            color: rgb(61, 61, 61);
            margin-right: 10px;
            padding-bottom: 0px;
            border-bottom: 2px solid #ffffff;
          }
          a:hover {
            border-bottom: 2px solid #0157ff;
          }
          img {
            width: 20px;
            margin-bottom: 0px;
          }
        }
        .Header1BoxMenu {
          display: flex;
          display: grid;
          grid-template-columns: auto auto;
          flex-wrap: wrap;
          transition: 0.3s all;
          position: absolute;
          top: 75px;
          right: 0%;
          background-color: white;
          box-shadow: 0 0 15px #f4f4fb6d;
          border: 1px solid #e9e9e988;
          border-top: none;
          padding: 10px 10px;
          width: calc(auto - 26%);
          margin-right: 26%;
          border-radius: 0px 0px 8px 8px;
          visibility: hidden;
          opacity: 0.1;

          .Header1BoxMenuBox {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            border-bottom: 1px solid #e9e9e988;
            padding: 15px 15px;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: rgb(61, 61, 61);
            white-space: nowrap;
            .Header1BoxMenuBox {
            }
            img {
              width: 50px;
            }
          }
          .Header1BoxMenuBox:hover {
            background-color: #0157ff;
            color: white;
          }
          .Header1BoxMenuBox:last-child {
            border-bottom: none;
          }
        }
      }
      .Header1Box:hover {
        .Header1BoxHedaer {
          h1 {
            border-bottom: 2px solid #0157ff;
          }
        }
        .Header1BoxMenu {
          opacity: 1;
          visibility: visible;
        }
      }
    }

  }

  .HeaderTop {
    display: flex;
    display: none;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 60px;
    padding: 30px 5%;
    // box-shadow: 0px 10px 15px -3px #0000001a;
    border-bottom: 2px solid #e6e6e6;
    z-index: 1;
    .Header2 {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      img {
        width: 28px;
        border-radius: 3px;
        margin-right: 10px;
      }
      h1 {
        font-size: 20px;
        font-weight: 300;
      }
      h2 {
        font-size: 12px;
        white-space: nowrap;
        font-weight: 300;
      }
    }
    .Header3 {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      img {
        max-width: 25px;
        max-height: 25px;
        min-width: 25px;
        min-height: 25px;
        filter: invert(0);
        cursor: pointer;
      }
      .menuCloseBtn {
        max-width: 20px;
        max-height: 20px;
        min-width: 20px;
        min-height: 20px;
      }
      img:first-child {
        width: auto;
        filter: invert(0);
      }
    }
  }
  .HeaderBottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 60px;
    padding: 20px 5%;
    .Header1Main {
      display: flex;
      .Header1 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        h2,
        a {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 17px;
          font-weight: 500;
          color: #000000;
          cursor: pointer;
          background-color: #e9e9e99c;
          border-radius: 0px;
          cursor: pointer;
          padding: 9px;
          padding-left: 20px;
          margin-right: 0px;

          img {
            // background-color: white;
            height: 30px;
            width: 30px;
            margin-left: 5px;
            // filter: invert(1);
          }
        }
        h2:hover,
        a:hover {
          background-color: #b8b8b8;
        }
      }
      .Header1:last-child {
        a {
          background-color: #0157ff;
          color: white;
          img {
            filter: invert(1);
          }
        }
      }
    }
    .Header2 {
      display: flex;
      align-items: center;
      justify-content: center;
      .Header2Box {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: 20px;
        padding: 15px 0px;
        a {
          font-size: 15px;
          font-weight: 500;
          color: black;
        }
        img {
          width: 20px;
          margin-right: 5px;
        }
      }
      .Header2Box:hover {
        border-bottom: 2px solid #0157ff;
      }
    }
  }

  // Applications Toturial
  .ApplicationsToturial {
    display: flex;
    // display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: #45454544;
    // backdrop-filter: blur(5px);
    .ApplicationsToturialShadow {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #45454544;
      // backdrop-filter: blur(5px);
      z-index: 1;
    }
    .ApplicationsToturialMain {
      width: 50%;
      max-width: 900px;
      height: auto;
      max-height: 90%;
      background-color: white;
      overflow: scroll;
      z-index: 2;
      padding: 50px 50px;
      border-radius: 10px;
      position: relative;
      animation: warningApply 0.4s ease-out;
      .ApplicationsToturialMainImgClose {
        display: flex;
        align-items: flex-end;
        justify-content: end;
        position: sticky;
        top: 0px;
        right: 0px;

        img {
          transition: 0.3s;
          cursor: pointer;
          width: 30px;
          padding: 5px;
          background-color: #d3d3d33f;
          backdrop-filter: blur(5px);
          border-radius: 50%;
          box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        }
        img:hover {
          background-color: #d3d3d3b0;
        }
      }
      .ApplicationsToturialMainHeader {
        width: 100%;
        h3 {
          color: #0157ff;
          font-size: 25px;
          font-weight: 600;
          margin-top: 0px;
        }
        h4 {
          color: #082538;
          font-size: 16px;
          font-weight: 300;
          margin-top: 15px;
        }
      }
      .ApplicationsToturialMainBody {
        width: 100%;
        height: auto;
        padding-top: 30px;
        .ApplicationsToturialMainBodyBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0px;
          width: 100%;
          border-bottom: 1px solid #e9e9e9;
          padding: 28px 0px;
          .ApplicationsToturialMainBodyBoxLeft {
            display: flex;
            align-items: center;
            justify-content: start;
            width: 20%;
            img {
              padding: 10px;
              max-width: 90px;
              width: 80%;
              background-color: #d3d3d33f;
              backdrop-filter: blur(5px);
              border-radius: 10px;
              box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
            }
          }
          .ApplicationsToturialMainBodyBoxRight {
            width: 80%;
            padding-left: 10px;
            h3 {
              color: #082538;
              font-size: 20px;
              font-weight: 500;
            }
            h4 {
              color: #082538;
              font-size: 15px;
              font-weight: 300;
              margin-top: 10px;
            }
            h5 {
              display: inline-block;
              border-left: 2px solid #0cff0cdd;
              color: #003a00dd;
              font-size: 15px;
              font-weight: 400;
              background-color: #0cff0c61;
              padding: 5px 15px;
              margin-top: 10px;
              border-radius: 0px 3px 3px 0px;
            }
          }
        }
        .ApplicationsToturialMainBodyBox:nth-child(4) {
          border-bottom: none;
        }
      }
      //
      .ApplicationsToturialMainBodyClose {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 50px;
        border-radius: 40px;
        background-color: #efefef;
        margin-top: 40px;
        cursor: pointer;
        transition: 0.3s;
        position: sticky;
        bottom: 0px;
        left: 0px;

        h1 {
          color: #082538;
          font-size: 20px;
          font-weight: 600;
        }
      }
      .ApplicationsToturialMainBodyClose:hover {
        background-color: #d3d3d3;
      }
    }
  }
}

@keyframes warningApply {
  0% {
    bottom: (-100%);
    opacity: 50%;
  }
  100% {
    bottom: (0);
    opacity: (100%);
  }
}
@media (max-width: 850px) {
  .Header {
    // padding: 8px 0%;
    // max-height: 55px;
    .HeaderTopComputer {
      display: none;
    }
    .HeaderTop {
      display: flex;
      height: 40px;
      padding: 25px 4%;
      .Header2 {
        h1 {
          font-size: 17px;
        }
      }
    }
    .HeaderBottom {
      height: 35px;
      padding: 30px 3%;
      .Header2 {
        .Header2Box {
          display: none;
          h2 {
            font-size: 14px;
          }
        }
      }
      .Header1Main {
        width: 100%;
        justify-content: space-between;
        gap: 10px;
        padding: 20px 0px;
        height: 100%;
        .Header1 {
          margin-left: 0px;
          width: 100%;
          h2,
          a {
            justify-content: center;
            padding: 15px 2px;
            font-size: 13px;
            font-weight: 600;
            width: 100%;
            img {
              display: none;
            }
          }
        }
      }
    }

    // ApplicationsToturial
    .ApplicationsToturial {
      .ApplicationsToturialMain {
        height: auto;
        max-height: 70%;
        width: 90%;
        padding: 20px 20px;
        overflow: scroll;
        .ApplicationsToturialMainImgClose {
          display: flex;
          align-items: flex-end;
          justify-content: end;
          position: sticky;
          top: 0px;
          right: 0px;
        }
        .ApplicationsToturialMainHeader {
          h3 {
            margin-top: -10px;
            font-size: 23px;
          }
          h4 {
            font-size: 14px;
            margin-top: 10px;
          }
        }
        .ApplicationsToturialMainBody {
          padding-top: 15px;
          .ApplicationsToturialMainBodyBox {
            padding: 18px 0px;
            align-items: flex-start;
            .ApplicationsToturialMainBodyBoxLeft {
              // display: none;
              align-items: flex-start;
              justify-content: start;
              img {
                padding: 7px;
              }
            }
            .ApplicationsToturialMainBodyBoxRight {
              width: 100%;
              padding-left: 10px;
              h3 {
                font-size: 17px;
              }
              h4 {
                font-size: 14px;
                margin-top: 7px;
              }
              h5 {
                font-size: 13px;
                padding: 5px 8px;
              }
            }
          }
        }
        .ApplicationsToturialMainBodyClose {
          margin-top: 30px;
          position: sticky;
          bottom: 0px;
          left: 0px;
          border-radius: 10px;
          box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  .Header {
    // padding-right: 20%;
    // padding-left: 20%;
    .HeaderTop,
    .HeaderBottom,
    .HeaderTopComputer {
      padding: 30px 13%;
    }
  }
}

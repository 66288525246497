.Faq {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: auto;
    padding-bottom: 40px;
    // MainBanner
    .FaqMainBanner {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        position: relative;
        min-height: 400px;
        min-width: 100vw;
        height: auto;
        background-color: #0157ff;
        padding: 50px 5% 50px 5%;
        h1 {
            font-size: 35px;
            font-weight: 600;
            color: white;
        }
        h2 {
            font-size: 20px;
            font-weight: 300;
            color: white;
            margin-top: 15px;
        }
        h5 {
            font-size: 25px;
            font-weight: 400;
            color: white;
            text-decoration: unset;
            width: 100%;
            margin-top: 30px;
        }
        .FaqMainBannerCategories {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            height: auto;
            padding-top: 10px;
            a {
                white-space: nowrap;
                margin: 10px 10px 0px 0px;
                font-size: 18px;
                font-weight: 400;
                color: white;
                text-decoration: unset;
                border: 1px solid white;
                padding: 5px 8px;
                border-radius: 5px;
            }
        }
    }
    .FaqMainList {
        display: flex;
        align-items: flex-start;
        padding: 20px 5%;
        .FaqList {
            width: 75%;
            padding-right: 30px;
            // FaqListHeaderResults
            .FaqListHeaderResults {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                h5 {
                    font-size: 17px;
                    font-weight: 400;
                    color: #0157ff;
                    border-bottom: 1px solid #0157ff;
                    cursor: pointer;
                    margin-top: 30px;
                }
                h5:hover {
                    border-bottom: 2px solid #0157ff;
                }
            }
            .FaqListNoResult {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px 20px;
                background-color: #f3f4f8;
                border-radius: 5px;
                margin-top: 30px;
                h1 {
                    text-align: center;
                    font-size: 17px;
                    font-weight: 400;
                    color: #0157ff;
                    // border-bottom: 1px solid #0157ff;
                }
            }

            .FaqPageHeaderImagePromise {
                width: 100%;
                margin-top: 20px;
                border-radius: 5px;
                padding: 20px;
                background-color: #f3f4f8;
                .FaqPageHeaderImagePromiseTop {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 15px;
                    border-bottom: 1px solid #e0e1e7;
                    .FaqPageHeaderImagePromiseTopLeft {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            margin: unset;
                            width: 25px;
                            width: 25px;
                        }
                        h2 {
                            font-size: 15px;
                            font-weight: 600;
                            margin-left: 10px;
                        }
                    }
                    a {
                        font-size: 14px;
                        font-weight: 300;
                        color: #0157ff;
                    }
                }
                p {
                    margin-top: 20px;
                    font-size: 14px;
                    font-weight: 300;
                    color: #515260;
                    a {
                        font-size: 14px;
                        font-weight: 300;
                        color: #0157ff;
                    }
                }
            }
            h6 {
                font-size: 25px;
                margin-top: 30px;
                color: #151515;
            }
            .FaqListBox {
                padding: 25px 20px;
                border-bottom: 2px solid #0157ff;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border-radius: 5px;
                margin-top: 20px;
                cursor: pointer;
                a {
                    text-decoration: unset;
                    width: 100%;
                    height: 100%;
                    h1 {
                        font-size: 1.5rem;
                        font-weight: 600;
                        color: #151515;
                    }
                    h2 {
                        font-size: 16px;
                        font-weight: 300;
                        color: #6e6f81;
                        margin-top: 5px;
                    }
                    h3 {
                        font-size: 14px;
                        font-weight: 300;
                        color: #373841;
                        margin-top: 5px;
                        margin-top: 20px;
                        span {
                            background-color: #0156ff38;
                            padding: 3px 10px;
                            border-radius: 3px;
                            border-left: 2px solid #0157ff;
                            width: auto;
                        }
                    }
                }
            }
            .FaqListBox:hover {
                transform: scale(1.03);
                transition: 0.5s;
            }
        }
        .FaqSearch {
            width: 25%;
            position: sticky;
            height: 800px;
            top: 130px;
            .FaqMainBannerCategories {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                width: 100%;
                height: auto;
                padding-top: 10px;
                a {
                    display: inline-block;
                    width: fit-content;

                    white-space: nowrap;
                    margin-top: 15px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #0157ff;
                    text-decoration: unset;
                    border-bottom: 2px solid white;
                }
                a:hover {
                    border-bottom: 2px solid #0157ff;
                }
            }
            h1 {
                font-size: 20px;
                margin-top: 30px;
                color: #151515;
            }
            .FaqSearchInput {
                position: relative;
                input {
                    margin-top: 15px;
                    padding: 0px 35px 0px 10px;
                    width: 100%;
                    height: 50px;
                    border-radius: 5px;
                    outline: none;
                    border: 1px solid #6e6f8190;
                    background-color: white;
                    font-size: 17px;
                    font-weight: 300;
                    outline: 1px solid #0156ff3a;
                }
                input:hover,
                input:focus {
                    outline: 3px solid #0156ff3a;
                }
                img {
                    position: absolute;
                    top: 30px;
                    right: 10px;
                    width: 20px;
                    background-color: #0156ff1f;
                    border-radius: 10px;
                    padding: 3px;
                }
                img:hover {
                    background-color: #0156ff3b;
                    cursor: pointer;
                }
            }
        }
    }
    .BlogsListReview {
        display: flex;
        flex-direction: column;
        padding: 40px 5%;
        width: 100%;
        gap: 20px;
        background-color: #f3f4f8;
        margin-top: 50px;
        .BlogsListReviewHead {
            border-bottom: 1px solid #4b657726;
            padding: 20px 0px;
            h1 {
                font-size: 25px;
                font-weight: 500;
                color: #000000;
            }
        }
        .BlogsListReviewList {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .BlogsListReviewBox {
                article {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                }
                display: flex;
                flex-direction: column;
                cursor: pointer;
                text-decoration: unset;
                width: 30%;
                background-color: white;
                margin-top: 10px;
                border-radius: 5px;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 09px 0px;

                .BlogsListReviewBoxLeft {
                    width: 100%;
                    max-height: 180px;
                    img {
                        max-height: 180px;
                        width: 100%;
                        object-fit: cover;
                        transition: all 0.5s ease-in-out;
                        border-radius: 5px 5px 0px 0px;
                    }
                }
                .BlogsListReviewBoxRight {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 20px;
                    h1 {
                        font-size: 15px;
                        font-weight: 500;
                        color: #0f212e;
                        width: 100%;
                    }
                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 30px;
                        width: 100%;
                        h5 {
                            color: #000000;
                            font-size: 15px;
                            font-weight: 500;
                        }
                        h4 {
                            color: #4f6473;
                            font-size: 13px;
                            font-weight: 300;
                        }
                    }
                }
            }
            .BlogsListReviewBox:hover {
                position: relative;
                .BlogsListReviewBoxRight {
                    h2 {
                        color: #0f212e;
                    }
                    img {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
    .FaqApply {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 0px 5%;
        padding-top: 40px;
        // background-color: rgb(240, 240, 240);
        h1 {
            text-align: center;
            font-size: 30px;
            font-weight: 500;
            color: #0f212ece;
        }
        .FaqApplyBox {
            margin-top: 40px;
            width: 100%;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 5px;
            padding: 40px;
            border-bottom: 2px solid #0157ff;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            img {
                width: 60px;
            }
            h2 {
                text-align: center;
                font-size: 25px;
                font-weight: 500;
                color: #0f212ece;
                margin-top: 10px;
            }
            a {
                margin-top: 35px;
                padding: 15px 45px;
                border-radius: 3px;
                background-color: #0157ff;
                border: unset;
                outline: unset;
                font-size: 20px;
                font-weight: 400;
                color: #ffffff;
                cursor: pointer;
            }
        }
    }
}
@media (max-width: 850px) {
    .Faq {
        padding: 0px 0% 30px 0%;
        .FaqMainBanner {
            padding: 40px 5% 40px 5%;
            h1 {
                font-size: 27px;
                font-weight: 600;
            }
            h2 {
                font-size: 18px;
            }
        }
        .FaqMainList {
            flex-direction: column-reverse;
            padding: 10px 0%;

            .FaqList {
                width: 100%;
                padding: 0px 5%;
                h6 {
                    margin-top: 15px;
                }
            }
            .FaqSearch {
                position: sticky;
                height: auto;
                top: 60px;
                width: 100%;
                background-color: white;
                padding: 10px 5%;
                border-bottom: 1px solid #4b657726;
                h1 {
                    margin-top: 0px;
                }
                .FaqMainBannerCategories,
                h1:first-child {
                    display: none;
                }
                .FaqSearchInput {
                    input {
                        max-height: 40px;
                    }
                    img {
                        top: 25px;
                    }
                }
            }
        }
        .BlogsListReview {
            .BlogsListReviewList {
                flex-direction: column;
                justify-content: start;
                .BlogsListReviewBox {
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }
    }
}
@media screen and (min-width: 1400px) {
    .Faq {
        .FaqMainBanner {
            padding: 0px 13%;
        }
        .FaqMainList {
            padding: 20px 13%;
            .FaqList {
                width: 75%;
                .FaqListBox {
                    width: 100%;
                }
            }
        }
        .BlogsListReview {
            padding: 40px 13%;
        }
        .FaqApply {
            padding: 0px 13%;
            padding-top: 40px;
        }
    }
}

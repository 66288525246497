.SiteMap {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: auto;
  padding: 50px 0px;
  .SiteMapMenu {
    display: flex;
    width: 100%;
    padding: 40px 5%;
    gap: 10px;
    border-bottom: 1px solid #4b657726;
    height: auto;
    flex-wrap: wrap;
    a {
      padding: 10px;
      font-size: 15px;
      color: #0f212e;
      text-decoration: unset;
      white-space: nowrap;
    }
  }
  .SiteMapDes {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 5%;
    gap: 10px;
    border-bottom: 1px solid #4b657726;
    height: auto;
    flex-wrap: wrap;
    .SiteMapDesBox {
      display: flex;
      flex-direction: column;
      h1{
        font-size: 25px;
        color: #0f212e;
        margin-top: 20px;
        margin-bottom: 10px;
      }
      a {
        padding: 10px;
        font-size: 15px;
        color: #0f212e;
        text-decoration: underline;
        // white-space: nowrap;
      }
    }
  }
}

@media (max-width: 690px) {
  .SiteMap {
    padding: 20px 0%;
    .SiteMapMenu {
      padding: 60px 5% 20px 5%;
      gap: 0px;
      a {
        padding: 0px 5px 10px 5px;
        margin: 10px 15px 0px 0px;
      }
    }
    .SiteMapDes {
      padding: 40px 5%;
      gap: 0px;
      .SiteMapDesBox {
        a {
          padding: 0px;
          margin: 15px 10px 0px 0px;
        }
      }
    }
  }
}
@media screen and (min-width: 1400px) {
  .SiteMap {
    padding: 80px 0% 80px 0%;
    .SiteMapMenu {
      padding: 40px 13%;
    }
    .SiteMapDes {
      padding: 40px 13%;
    }
  }
}

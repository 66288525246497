.Contact {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: auto;
    // MainBanner
    .MainBanner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: 65vh;
        min-height: 600px;
        min-width: 100vw;
        max-height: 65vh;
        background-color: #0157ff;
        padding: 100px 5% 0px 5%;
        overflow: hidden;
        .MainBannerLeft {
            width: 50%;
            padding-right: 20px;
            margin-top: -100px;
            h1 {
                font-size: 45px;
                font-weight: 600;
                color: white;
            }
            h2 {
                font-size: 25px;
                font-weight: 300;
                color: white;
                margin-top: 15px;
            }
            button {
                margin-top: 35px;
                padding: 12px 50px;
                border-radius: 50px;
                background-color: white;
                border: unset;
                outline: unset;
                font-size: 23px;
                font-weight: 500;
                cursor: pointer;
                color: #0157ff;
                background: white;
            }
            button:hover {
                background-color: #e9e9e9;
            }
        }
        .MainBannerRight {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 50%;
            height: 100%;
            position: relative;
            img {
                position: absolute;
                bottom: -450px;
                margin-top: 100px;
                width: 500px;
                z-index: 2;
            }
            .MainBannerRightBubble1 {
                // display: none;
                position: absolute;
                right: 270px;
                bottom: -200px;
                width: 200px;
                height: 200px;
                border-radius: 50%;
                background-color: #ffffff;
                z-index: 1;
                box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
            }
            .MainBannerRightBubble2 {
                // display: none;
                position: absolute;
                right: 50px;
                bottom: -50px;
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background-color: #ffffff;
                z-index: 1;
                box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
            }
        }
    }
    .MainBanner2Top2 {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        background-color: #e9e9e99c;
        position: relative;
        z-index: 2;
        padding-bottom: 60px;

        .MainBanner2TopBox {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 30px 50px 10px 50px;
            background-color: white;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            border-radius: 8px;
            width: 90%;
            margin-top: -40px;
            h2 {
                text-align: center;
                font-size: 23px;
                font-weight: 600;
                color: #0157ff;
            }
            .MainBanner2TopBoxMenu {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
                .MainBanner2TopBoxBox {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    flex-direction: column;
                    border-right: 1px solid #e9e9e988;
                    padding: 15px 30px;
                    width: auto;
                    height: 170px;
                    h3 {
                        text-align: center;
                        font-size: 25px;
                        font-weight: 600;
                        color: rgb(61, 61, 61);
                        margin-top: 10px;
                        white-space: nowrap;
                        border-bottom: 2px solid #0157ff;
                        padding-bottom: 8px;
                    }
                    a {
                        text-align: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgb(61, 61, 61);
                        margin-top: 10px;
                        margin-top: 20px;
                        color: #0157ff;
                        border-bottom: 1px solid white;
                    }
                    a:hover {
                        border-bottom: 1px solid #0157ff;
                    }
                }
                .MainBanner2TopBoxBox:hover {
                    background-color: #0156ff11;
                    border-radius: 15px;
                }
                .MainBanner2TopBoxBox:last-child {
                    border-right: none;
                }
            }
        }
    }
    .ContactFaq {
        height: auto;
        width: 100%;
        padding: 50px 5%;
        background-color: white;
        h1 {
            font-size: 45px;
            font-weight: 600;
            color: #0157ff;
            text-align: center;
        }

        .ContactFaqList {
            margin-top: 40px;
            .ContactFaqListBox {
                border-bottom: 1px solid #d4d4d4;
                padding: 20px 0px;
                .ContactFaqListBoxQuestion {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    img {
                        width: 20px;
                    }
                    h2 {
                        font-size: 19px;
                        font-weight: 500;
                        color: #0157ff;
                        margin-top: 15px;
                    }
                }
                .ContactFaqListBoxAnswer {
                    margin-top: 20px;
                    p {
                        font-size: 15px;
                        font-weight: 300;
                        color: #606060;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
    .ContactBanner2 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 70px 5%;
        width: 100%;
        background-color: #0157ff;
        h2 {
            text-align: center;
            font-size: 35px;
            font-weight: 600;
            color: white;
        }
        a {
            font-size: 18px;
            font-weight: 300;
            color: white;
            margin-top: 30px;
            text-align: center;
            u {
                text-decoration: underline;
            }
        }
    }
    .ContactFirst {
        padding: 40px 5%;
        background-color: #e9e9e99c;

        .ContactFirstHeader {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 50px;
            h1 {
                font-size: 28px;
                font-weight: 600;
                color: #0157ff;
            }
        }
        .ContactFirstBody {
            display: flex;
            width: 100%;
            margin-top: 40px;
            height: auto;
            gap: 30px;
            .ContactTop {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;
                width: 45%;
                margin-bottom: 0px;
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 8px;
                    filter: brightness(80%);
                }
                .ContactTopText {
                    position: absolute;
                    bottom: 30px;
                    left: 30px;
                    padding-right: 20px;
                    h1 {
                        font-size: 40px;
                        color: #ffffff;
                        text-align: left;
                    }

                    h3 {
                        color: rgb(255, 255, 255);
                        text-align: left;
                        font-size: 14px;
                        font-weight: 300;
                        margin-top: 16px;
                    }
                }
            }
            form {
                display: flex;
                align-items: start;
                justify-content: start;
                flex-direction: column;
                width: 60%;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                border-radius: 8px;
                padding: 30px;
                background-color: white;

                h4 {
                    font-size: 20px;
                    font-weight: 600;
                    color: #0157ff;
                    margin-top: 15px;
                }
                h5 {
                    font-size: 15px;
                    font-weight: 300;
                    color: #606060;
                    margin-top: 15px;
                }
                h2 {
                    font-size: 15px;
                    font-weight: 500;
                    color: #0157ff;
                    margin-top: 15px;
                }
                .ContactFirsthhh {
                    display: flex;
                    gap: 20px;
                    width: 100%;
                    .ContactFirsthhhBox:nth-child(2) {
                        width: 30%;
                    }
                    .ContactFirsthhhBox:nth-child(1) {
                        width: 70%;
                    }
                }
                input,
                select,
                textarea {
                    width: 100%;
                    height: 50px;
                    background: none;
                    color: #727272;
                    outline: none;
                    border: none;
                    border: 2px solid #d4d4d4;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    padding: 10px;
                    text-align: left;
                    font-size: 15px;
                    font-weight: 300;
                    margin-top: 10px;
                }
                input:focus,
                select:focus,
                textarea:focus {
                    border: 2px solid #9b9b9b;
                }
                input:hover,
                select:hover,
                textarea:hover {
                    border: 2px solid #9b9b9b;
                }
                .Message {
                    height: 120px;
                }
                input::placeholder,
                textarea::placeholder {
                    color: #002f68;
                    font-size: 15px;
                }
                input:focus::placeholder {
                    color: transparent;
                }
                input[type="submit"] {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #0157ff;
                    color: #ffffff;
                    border-radius: 10px;
                    padding: 10px 20px;
                    margin-top: 30px;
                    outline: unset;
                    border: unset;
                    cursor: pointer;
                    border-bottom: unset;
                    font-size: 25px;
                    font-weight: 600;
                }
            }
        }
    }
}

@media (max-width: 790px) {
    .Contact {
        .MainBanner {
            flex-direction: column;
            padding: 20px 5% 20px 5%;
            max-height: 100vh;
            height: auto;
            .MainBannerLeft {
                width: 100%;
                height: 50%;
                margin-top: -270px;
                padding-right: 0px;
                h1 {
                    font-size: 35px;
                }
                h2 {
                    font-size: 20px;
                }
                button {
                    width: 100%;
                }
            }
            .MainBannerRight {
                width: 100%;
                height: 100%;
                margin-top: 50px;
                img {
                    position: absolute;
                    bottom: -450px;
                    margin-top: 100px;
                    width: 300px;
                    z-index: 2;
                }
                .MainBannerRightBubble1 {
                    right: 190px;
                    bottom: -220px;
                    width: 120px;
                    height: 120px;
                }
                .MainBannerRightBubble2 {
                    right: 50px;
                    bottom: -130px;
                    width: 90px;
                    height: 90px;
                }
            }
        }
        .MainBanner2Top2 {
            .MainBanner2TopBox {
                padding: 30px 20px 20px 20px;
                .MainBanner2TopBoxMenu {
                    flex-direction: column;
                    .MainBanner2TopBoxBox {
                        width: 100%;
                        height: auto;
                        justify-content: start;
                        padding: 15px 20px;
                        border-bottom: 1px solid #e9e9e988;
                        border-right: none;

                        h3 {
                            text-align: center;
                            font-size: 18px;
                            font-weight: 500;
                            color: rgb(61, 61, 61);
                            margin-top: 10px;
                        }
                        img {
                            width: 50px;
                        }
                    }
                    .MainBanner2TopBoxBox:last-child {
                        border-right: none;
                        border-bottom: none;
                    }
                }
            }
        }
        .ContactFaq {
            h1 {
                font-size: 24px;
            }
        }
        .ContactBanner2 {
            h2 {
                font-size: 28px;
            }
            a {
                font-size: 15px;
            }
        }
        .ContactFirst {
            padding: 40px 5%;
            .ContactFirstBody {
                flex-direction: column-reverse;
                .ContactTop {
                    width: 100%;
                    height: 40vh;
                    margin-top: 30px;
                }
                form {
                    width: 100%;
                    // padding-left: 0px;

                h4 {
                    font-size: 16px;
                    font-weight: 600;
                    color: black;
                    margin-top: 15px;
                }
                    h5 {
                        margin-bottom: 20px;
                        font-size: 13px;
                    }
                    h2 {
                        margin-top: 10px;
                    }
                    input,
                    select,
                    textarea {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1400px) {
    .Contact {
        .MainBanner {
            padding-right: 13%;
            padding-left: 13%;
        }
        .MainBanner2Top2 {
            padding-right: 13%;
            padding-left: 13%;

            .MainBanner2TopBox {
                width: 100%;
            }
        }
        .ContactBanner {
            padding: 100px 20% 200px 20%;
        }
        .ContactFaq {
            padding: 50px 20%;
            .ContactFaqList {
            }
        }
        .ContactFirst {
            padding: 40px 13%;
        }
    }
}
